.study-containers {
  max-width: 181px;
  min-height: 213px !important;
  background-color: #eff2ff;
  margin-left: 25px !important;
  margin-top: 25px !important;
  display: flex;
  justify-content: center;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 1px 4px 13px 2px rgba(101, 101, 101, 0.22);
    transform: translateY(-5px);
  }
}


.btn-close {
  display: inline-block;
  background-color: red;
}

.templating-select {
  background-color: white;
}


.study-body-text {
  font-size: 10px;
}

.modal-header {
  border-bottom: 0;
}

.modal-body {
  padding: 2rem;
}

.modal-body .text-center h4 {
  font-size: 1.5rem;
}

.modal-body .text-center p {
  font-size: 1rem;
}

.input-group {
  // border-radius: 0.5rem;
  // background-color: #f8f9fa;
  .form-control {
    // background-color: white !important;
    border: none;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    box-shadow: none;
    &:focus {
      box-shadow: none;
      border-color: #007bff;
    }
  }
  .input-button {
    border: 1px solid #007bff;
    background-color: #007bff;
    color: white;
    padding: 0.375rem 0.75rem;
    height: 35.5px;
    border-radius: 0.25rem;
    transition: background-color 0.2s ease, border-color 0.2s ease;

    &:hover {
      background-color: #0056b3;
      border-color: #0056b3;
    }
  }
  .btn {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

.select2-selection {
  // border-radius: 0.5rem;
  width: 100%;
  // border: 1px solid #ced4da;
  // padding: 0.375rem 0.75rem;
  transition: border-color 0.2s ease;

  &:focus,
  &:hover {
    border-color: #007bff;
  }
}
.favorite-icon-ORANGE,
.favorite-icon-YELLOW,
.favorite-icon-GREEN,
.favorite-icon-PURPLE,
.favorite-icon-LIGHT_BLUE {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  margin-bottom: 10px;

  & img {
    width: 40px;
    height: 40px;
  }
}