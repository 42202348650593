// Font Family
$poppins: 'Poppins', sans-serif;


// Colors ---------------
$white: #fff;
$black: #222;
$grey: #f7f7fd;


// Theme Color
$primary: #AE252C;

// title color
$title-color:#AE252C;

// Text Color
$text-color-one: #121212;
$text-color-two: #a4a4a4;
$text-color-three: #6c6c6c;


// Secondary Color
$secondary-color-one: #393852;
$secondary-color-two: #e5e5e8;
$secondary-color-three: #34334a;

// Light Color
$light-color-one: #e9ecef;
$light-color-two: #f4f6f7;
$light-color-three: #cbced1;


// Responsive Variables
$desktop: 'only screen and (min-width: 1400px)';
$laptop: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';










