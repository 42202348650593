// * {
//   font-family: poppins;
// }
.page-content {
  .back {
    border-radius: 2px;
    background: #f0f0f2;
    padding: 7px;
    margin-bottom: 20px !important;
  }
}
.img-contents {
  display: flex;
  width: 100%;
  min-height: 70px;
  border: 0.25rem;
  font-size: 30px;
  background-color: #eff2ff;
  justify-content: center;
  border-radius: 3px;
}

.study-container1 {
  max-width: 181px;
  background-color: #eff2ff;
  margin-left: 25px !important;
  margin-top: 25px !important;
}

.studies-card {
  padding: 0 !important;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.25rem;
  max-width: 181px;
  min-height: 213px;

  .favorite-icon {
    background-color: #eff2ff;
    padding: 8px;

    .img-content {
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      background-color: #eff2ff;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }

    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #d7ffe2 !important;
    }
  }
  .studies-card-content {
    padding: 20px !important;
    margin-top: 2px !important;
  }

  .study-title {
    font-size: 12px;
    font-weight: 600;
    color: #383838;
  }
  .study-secondary-text {
    color: #9f9f9f;
    font-size: 8px;
    font-weight: 400;
  }
}
.form-section-title {
  color: #173261;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.form-section-title-2 {
  color: #214075;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.26px;
}

.study-card:hover {
  background-color: lightgrey !important;
  cursor: pointer !important;
}

.studies-card {
  .favorite-icon-lightblue {
    background-color: #eff2ff !important;
    padding: 8px;
    .img-content-lightblue {
      background-color: #eff2ff !important;
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }

    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #d7ffe2 !important;
      text-wrap: wrap;
      text-align: start;
    }
  }
  .favorite-icon-orange {
    background-color: #ffede2 !important;
    padding: 8px;
    .img-content-orange {
      background-color: #ffede2 !important;
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }
    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #d7ffe2 !important;
      text-wrap: wrap;
      text-align: start;
    }
  }

  .favorite-icon-green {
    background-color: #d7ffe2 !important;
    padding: 8px;
    .img-content-green {
      background-color: #d7ffe2 !important;
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }

    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #d7ffe2 !important;
      text-wrap: wrap;
      text-align: start;
    }
  }

  .favorite-icon-purple {
    background-color: #f2e4ff !important;
    padding: 8px;
    .img-content-purple {
      background-color: #f2e4ff !important;
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }

    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #d7ffe2 !important;
      text-wrap: wrap;
      text-align: start;
    }
  }

  .favorite-icon-yellow {
    background-color: #ffefcc !important;
    padding: 8px;
    .img-content-yellow {
      background-color: #ffefcc !important;
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }

    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #d7ffe2 !important;
      text-wrap: wrap;
      text-align: start;
    }
  }
}
