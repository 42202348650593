.form-builder {
  * {
    font-family: "Poppins" !important;
  }
  .form-builder-Input {
    font-size: 23px;
    padding: 20px;
    width: 72.5%;
    margin-bottom: 15px;
    margin-top: -12px;
  }
  .form-builder-autosave-notification {
    font-size: 15px;
    // padding: 4px;
    width: 72.5%;
    // margin-bottom: 15px;
    // margin-top: -12px;
  }
  .empty-title:focus {
    border: 2px solid red;
    border-radius: 4px;
  }
  .float-container {
    border: 3px solid #fff;
    padding: 20px;
    font-family: "Poppins";
  }

  .float-child {
    width: 50%;
    float: right;
    padding: 20px;
    border: 0.5px groove gray;
    border-radius: 20px;
    min-height: 800px;
  }

  button {
    // padding: 10px;
    margin: 20px;
    // width: 150px;
    // height: 40px;
    // color: whitesmoke;
    // background-color: gray;
    cursor: pointer;
    transition: width 0.5s;
    // border: 4px solid #0f0064;
  }

  // button:hover {
  //   padding: 10px;
  //   margin: 20px;
  //   width: 160px;
  // }

  .section-spacing {
    margin-bottom: 10px;
  }
  .formbuilder-embedded-bootstrap .stage-wrap {
    height: 450px !important;
    overflow-y: scroll;
    scrollbar-width: inherit;
  }
  .cb-wrap .pull-right {
    margin-top: -63px;
  }

  .section-spacing .stage-wrap {
    row-gap: 10px !important;
  }
  .form-field {
    border: 1px solid rgba(0, 0, 0, 0.148);
    margin-bottom: 15px !important ;
    border-radius: 5px;
    padding: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .form-field:hover {
    box-shadow: none !important;
  }

  .section-spacing .btn-toolbar {
    margin-top: 20px !important;
    column-gap: 20px !important;
  }
  // .editor-quote {
  //   font-size: 13px !important;
  // }
  .field-label {
    font-size: 13px !important;
  }
  .editor-text-bold {
    // font-size: 16px !important;
  }
  .wizard-steps {
    width: 200px;
    display: flex;
    // justify-content: ;
  }
  .delete-button {
    display: none;
  }

  .wizard-steps:hover .delete-button {
    display: inline-block;
  }
  .remove-button:hover {
    border: black;
  }
}
