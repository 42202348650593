.css-t3ipsp-control {
  // display: none !important;
  outline: none !important;
  border: none;
}
.element.style {
  display: none !important;
}
.input[type="text"]:focus {
  outline: none !important;
  border-color: none !important;
  outline-offset: 0px !important;
}
.ant-select1 {
  width: 500px !important;
}
.ant-input1 {
  width: 500px;
  background-color: transparent;
}
.ant-select-item-option-content {
  font-size: 13px !important;
}
.ant-select-selection-item {
  font-size: 12px;
  font-weight: lighter;
}
