* {
  font-family: "Poppins", sans-serif;
}
.study {
  text-align: center;
  font-size: 10px;
}

.line {
  width: 350%;
  margin: 10px 0;
  border-bottom: 1px solid;
  color: #909090;
}

.floating_button {
  position: relative;
  margin-left: 80%;
  margin-right: 10%;
}

.que-block {
  textarea {
    resize: none;
    font-size: 15px;
    line-height: 1.2rem;
  }

  .que-block-textarea::-webkit-scrollbar {
    display: none;
  }

  .que-block-textarea:focus {
    border-bottom: 1px solid grey !important;
  }

  .radio {
    line-height: rem;
    margin-left: 0px;
    font-size: 1rem;
    color: #6b6b6b;
  }
}

.que-block-w-150 {
  width: 150%;
}

.que-block-container {
  // border: 1px solid grey;
  border-radius: 7px;
}

.ant-select-selection {
  background-color: transparent;
  border: none;
}

.form-component-min-width {
  @media (min-width: 576px) {
    min-width: 360px;
  }

  @media (min-width: 796px) {
    min-width: 520px;
  }

  @media (min-width: 992px) {
    min-width: 640px;
  }
}

.form-section {
  .ant-btn {
    background-color: transparent;
    border: 1px color(#214075);
  }

  .card-title {
    color: #214075;
    font-size: 22px !important;
  }

  .card-title-desc {
    color: #989898;
    font-size: 18px !important;
    font-weight: 400;
  }

  .form-text-question {
    font-size: 16px !important;
    color: #6b6b6b;
  }

  .ant-input {
    background-color: #f0f3fb;
    border: 1px solid #ced4da !important;
    padding: 5;
    margin-bottom: 15px;
    color: #173261;
    height: 46px;
    font-weight: 500;
    font-size: 16px;
  }

  .checkboxes {
    margin-bottom: 15px;
    row-gap: 10px;
    font-size: 13px;
    font-weight: 100;
  }

  .form-check {
    border-color: #e0dcdc !important;
    height: 46px;
    align-items: center;
    padding: 0px;

    .dropdown-content {
      font-weight: 500;
      border-radius: 4px;
      background: #f0f3fb;
    }

    .ant-select {
      height: 44.5px;
      border-radius: 7px;
    }
  }

  .ant-input:hover {
    border: none;
    outline: none;
  }

  .ant-input:focus .ant-btn:focus {
    background-color: #7f8387;
    border: none;
    outline: none;
  }

  .ant-select {
    padding: 5;
    margin-bottom: 15px;

    .ant-select-selector {
      border: none;
      background-color: transparent;
      color: #173261;
      font-weight: 800 !important;
      font-size: 16px;
      padding: 5px;
    }

    .ant-select-selector:hover {
      background-color: #7f8387 !important;
    }
  }

  .radio-check {
    display: flex;
    height: fit-content;
    color: #6b6b6b;
    font-size: 18px;
    font-weight: 500;
  }

  .bg-blue {
    border: 1px solid;
    border-radius: 7px;
    height: 46px;
  }

  .form-check-pl-0 {
    padding-left: 0px !important;
  }

  .color-red {
    color: red;
  }
}

.table-responsive {
  overflow: hidden !important;

  .form-check-pl-0 {
    padding-left: 0px;
    height: 46px;
  }
}

.page-background {
  width: 880px;
}

.create-study {
  margin-left: 86%;
  width: 100%;
}

.steps {
  margin-bottom: 10px;

  .ant-steps-item-title {
    font-family: "Poppins" !important;
  }
  .ant-steps-item-icon {
    background-color: #32599d !important;
    border: none !important;
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: #f0f0f2 !important;
      border: none !important;
    }
  }
}

.ant-radio-wrapper {
  color: #6b6b6b;
  font-size: 18px;
}

.study-form-button {
  display: inline-flex;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: #214075;
  margin-left: 700px;
  margin-bottom: 10px;
}

.study-form-done-button {
  display: inline-flex;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: #214075;
  margin-left: 700px;
}

.study-form-previous-button {
  display: flex;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);

  margin-right: -690px !important;
}

.study-form-previous-button:hover {
  border: 1px solid blue;
  color: white;
  cursor: pointer;
}
.disabled {
  background-color: #d3d3d3; /* Set the background color to a grey shade */
  cursor: not-allowed; /* Change the cursor to indicate that the button is not clickable */
}
.active {
  font-weight: 400 !important;
  font-size: 13px !important;
}
