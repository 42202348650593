/*-----------------------------------------------------------------------------------

    Template Name: Smart - Muultipurpose Landing Page Template
    Template URL: uideck.com/templates/smart
    Description: Smart - Muultipurpose Landing Page Template
    Author: UIdeck
    Author URL: https://uideck.com/
    Support: https://uideck.com/support/
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/

.landing-page{
    @import 'variables';
    @import 'mixin';
    @import 'variables';
    @import 'common';
    @import 'button';
    @import 'header';
    @import 'about';
    @import 'portfolio';
    @import 'services';
    @import 'pricing';
    @import 'call-to-action';
    @import 'testimonial';
    @import 'client';
    @import 'blog';
    @import 'contact';
    @import 'footer';
}
