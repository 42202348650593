/*===========================
       3.HEADER css 
===========================*/

/*===== NAVBAR EIGHT =====*/

.navbar-area {
  // background: -webkit-linear-gradient(left, rgba(67,202,233,1) 0%, rgba(56,249,215,1) 100%);
  // background: -o-linear-gradient(left, rgba(67,202,233,1) 0%, rgba(56,249,215,1) 100%);
  // background: linear-gradient(to right, rgba(67,202,233,1) 0%, rgba(56,249,215,1) 100%);
  padding: 10px 0;
  // background-color: rgb(230, 229, 229);
  // backdrop-filter: blur(3px);
  color: black;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  @include transition(0.3s);
  transition: background-color 0.3s ease;

  // &.scrolled {
  //   background-color: rgb(185, 47, 47) !important;// Change background color when scrolled
  // }
  .navbar-brand {
    .text {
      color: black;
    }
  }
  .navbar-brand img {
    max-width: 150px;
    color: black;
  }

  @media #{$md} {
    padding: 10px 0;
  }

  @media #{$xs} {
    padding: 10px 0;
  }

  & .navbar {
    position: relative;
    padding: 0;

    & .navbar-toggler {
      & .toggler-icon {
        width: 30px;
        height: 2px;
        background-color: $text-color-one;
        color: black;
        margin: 5px 0;
        display: block;
        position: relative;
        @include transition(0.3s);
      }

      &.active {
        & .toggler-icon {
          &:nth-of-type(1) {
            @include transform(rotate(45deg));
            top: 7px;
          }

          &:nth-of-type(2) {
            opacity: 0;
          }

          &:nth-of-type(3) {
            @include transform(rotate(135deg));
            top: -7px;
          }
        }
      }
    }

    & .navbar-collapse {
      @media #{$md} {
        position: absolute;
        top: 108%;
        left: 0;
        width: 100%;
        background-color: $light-color-two;
        z-index: 8;
        padding: 10px 16px;
        @include box-shadow(0 3px 6px 0 rgba(0, 0, 0, 0.16));
      }

      @media #{$xs} {
        position: absolute;
        top: 108%;
        left: 0;
        width: 100%;
        background-color: $light-color-two;
        z-index: 8;
        padding: 10px 16px;
        @include box-shadow(0 3px 6px 0 rgba(0, 0, 0, 0.16));
      }
    }

    & .navbar-nav {
      margin-right: 24px;

      @media #{$md} {
        margin-right: 0;
      }

      @media #{$xs} {
        margin-right: 0;
      }

      & .nav-item {
        position: relative;

        & a {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          padding: 11px 16px;
          color: black;
          text-transform: uppercase;
          position: relative;
          // border-radius: 5px;
          @include transition(0.3s);
          margin: 14px 0;

          @media #{$md} {
            padding: 10px 0;
            display: block;
            border: 0;
            margin: 0;
            color: $text-color-one;
          }

          @media #{$xs} {
            padding: 10px 0;
            display: block;
            border: 0;
            margin: 0;
            color: $text-color-one;
          }

          &::before {
            position: absolute;
            content: "";
            width: 32px;
            height: 4px;
            background-color: $white;
            left: 50%;
            bottom: 0;
            @include transition(0.3s);
            @include transform(translateX(-50%) scaleX(0));

            @media #{$md} {
              display: none;
            }

            @media #{$xs} {
              display: none;
            }
          }
        }
        &.active,
        &:hover {
          & > a {
            &::before {
              @include transform(translateX(-50%) scaleX(1));
            }
          }
        }

        & .sub-menu {
          position: absolute;
          right: 0;
          top: 100%;
          width: 260px;
          background-color: $white;
          // border-radius: 5px;
          opacity: 0;
          visibility: hidden;
          @include transition(0.3s);
          z-index: 99;
          @include box-shadow(0 2px 6px 0 rgba(0, 0, 0, 0.16));

          @media #{$md} {
            position: relative !important;
            width: 100% !important;
            left: 0 !important;
            top: auto !important;
            opacity: 1 !important;
            visibility: visible !important;
            display: none;
            right: auto;
            @include transform(translateX(0%));
            @include transition(none);
            @include box-shadow(none);
            text-align: left;
            border-top: 0;
          }

          @media #{$xs} {
            position: relative !important;
            width: 100% !important;
            left: 0 !important;
            top: auto !important;
            opacity: 1 !important;
            visibility: visible !important;
            display: none;
            right: auto;
            @include transform(translateX(0%));
            @include transition(none);
            @include box-shadow(none);
            text-align: left;
            border-top: 0;
          }

          & li {
            position: relative;

            & .sub-nav-toggler {
              color: $text-color-one;
              @include transition(0.3s);
            }

            & a {
              display: block;
              padding: 8px 24px;
              position: relative;
              color: $text-color-one;
              @include transition(0.3s);
              border-radius: 0;
              margin: 0 0;

              & i {
                float: right;
                font-size: 14px;
                margin-top: 5px;

                @media #{$md} {
                  display: none;
                }

                @media #{$xs} {
                  display: none;
                }
              }

              & .sub-nav-toggler {
                & i {
                  display: inline-block;
                }
              }

              &::after {
                position: absolute;
                content: "";
                top: 0;
                left: 0px;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                background: -webkit-linear-gradient(
                  left,
                  rgba(0, 103, 244, 1) 0%,
                  rgba(43, 219, 220, 1) 100%
                );
                background: -o-linear-gradient(
                  left,
                  rgba(0, 103, 244, 1) 0%,
                  rgba(43, 219, 220, 1) 100%
                );
                background: linear-gradient(
                  to right,
                  rgba(0, 103, 244, 1) 0%,
                  rgba(43, 219, 220, 1) 100%
                );
                opacity: 0;
                @include transition(0.3s);
                z-index: -1;
              }

              &::before {
                display: none;
              }
            }

            & .sub-menu {
              right: auto;
              left: 100%;
              top: 0;
              opacity: 0;
              visibility: hidden;
              @include transition(0.3s);

              @media #{$md} {
                padding-left: 30px;
              }

              @media #{$xs} {
                padding-left: 30px;
              }

              & li {
                & a {
                }
              }
            }

            &:hover {
              & .sub-menu {
                opacity: 1;
                visibility: visible;
              }

              & .sub-nav-toggler {
                color: black;
              }

              & > a {
                color: black;

                &::after {
                  opacity: 1;
                }

                &::before {
                  opacity: 1;
                }
              }
            }
          }
        }

        &:hover {
          & .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }

        .sub-nav-toggler {
          display: none;

          @media #{$md} {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px 14px;
            font-size: 16px;
            background: none;
            border: 0;
            color: black;
          }

          @media #{$xs} {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px 14px;
            font-size: 16px;
            background: none;
            border: 0;
            color: black;
          }
        }
      }
    }

    & .navbar-btn {
      @media #{$md} {
        position: absolute;
        right: 70px;
        top: 11px;
      }

      @media #{$xs} {
        position: absolute;
        right: 60px;
        top: 11px;
      }

      & .menu-bar {
        font-size: 32px;
        position: relative;
        overflow: hidden;
        color: black;
        @include transition(0.3s);
      }
    }
  }

  &.navbar-transparent {
    background: transparent;

    & .navbar {
      & .navbar-toggler {
        & .toggler-icon {
          background-color: $white;
        }
      }

      & .navbar-nav {
        & .nav-item {
          & a {
            color: black;

            @media #{$md} {
              color: $text-color-one;
            }

            @media #{$xs} {
              color: $text-color-one;
            }

            &::before {
              background-color: $primary;
            }
          }

          &.active,
          &:hover {
            & a {
              color: $primary;
            }
          }
        }
      }

      & .navbar-btn {
        & .menu-bar {
          color: black;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    &.sticky {
      background-color: $white;

      & .navbar {
        & .navbar-toggler {
          & .toggler-icon {
            background-color: $text-color-one;
          }
        }

        & .navbar-nav {
          & .nav-item {
            & a {
              color: $text-color-one;
            }

            &.active,
            &:hover {
              & a {
                color: $primary;
              }
            }
          }
        }

        & .navbar-btn {
          & .menu-bar {
            color: $text-color-one;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }

  &.navbar-transparent-2 {
    background: transparent;

    & .navbar {
      & .navbar-toggler {
        & .toggler-icon {
          background-color: $white;
        }
      }

      & .navbar-nav {
        & .nav-item {
          & a {
            color: $text-color-one;

            @media #{$md} {
              color: $text-color-one;
            }

            @media #{$xs} {
              color: $text-color-one;
            }

            &::before {
              background-color: $primary;
            }
          }

          &.active,
          &:hover {
            & a {
              color: $primary;
            }
          }
        }
      }

      & .navbar-btn {
        & .menu-bar {
          color: black;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    &.sticky {
      background-color: $white;
    }
  }

  &.sticky {
    position: fixed;
    @include box-shadow(0 3px 6px 0 rgba(0, 0, 0, 0.16));
    padding: 5px 0;
  }
}

.sidebar-right {
  position: fixed;
  top: 0;
  right: 0;
  background-color: $light-color-two;
  height: 100%;
  width: 300px;
  padding-top: 80px;
  z-index: 9999;
  @include transform(translateX(100%));
  @include transition(0.3s);

  &.open {
    @include transform(translateX(0));
  }

  @media #{$xs} {
    width: 250px;
  }

  & .sidebar-close {
    position: absolute;
    top: 30px;
    left: 30px;

    & .close {
      font-size: 24px;
      color: $text-color-one;
    }
  }

  & .sidebar-content {
    & .sidebar-logo img {
      max-width: 50%;
    }

    & .sidebar-menu {
      margin-top: 40px;

      & ul {
        & li {
          & a {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            padding: 11px 16px;
            color: $text-color-one;
            text-transform: uppercase;
            position: relative;
            border-radius: 5px;
            @include transition(0.3s);
            border-bottom: 1px solid $light-color-three;
            display: block;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }

    & .sidebar-social {
      margin-top: 30px;

      & span {
        font-size: 16px;
        line-height: 24px;
        color: $text-color-three;
        text-transform: uppercase;
        font-weight: 700;
        margin-right: 14px;
      }

      & ul {
        & li {
          display: inline-block;
          margin-right: 5px;

          & a {
            font-size: 32px;
            color: $primary;
            @include transition(0.3s);
          }
        }
      }
    }
  }
}

.overlay-right {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 999;

  &.open {
    display: block;
  }
}

/*===== SLIDER ONE =====*/

.slider-area {
  padding-top: 94px;

  @media #{$md} {
    padding-top: 80px;
  }
  @media #{$xs} {
    padding-top: 80px;
  }

  & .bd-example {
    & .carousel {
      & .carousel-inner {
        background-color: $primary;

        & .carousel-item {
          height: 700px;

          @media #{$lg} {
            height: 600px;
          }

          @media #{$md} {
            height: 600px;
          }

          @media #{$xs} {
            height: 400px;
          }

          & .carousel-caption {
            top: 50%;
            bottom: auto;
            @include transform(translateY(-50%));
            width: 100%;
            left: 0;

            & .carousel-title {
              font-size: 44px;
              font-weight: 700;
              color: #000;
              line-height: 55px;

              @media #{$xs} {
                font-size: 24px;
                line-height: 32px;
              }
            }

            & .carousel-btn {
              & li {
                display: inline-block;
                margin: 16px 8px 0;

                @media #{$xs} {
                  margin: 16px 3px 0;
                }

                & a {
                  &.rounded-three {
                    border: 0;
                  }

                  &.rounded-one {
                    border-color: $white;
                    color: black;

                    &:hover {
                      background-color: rgba(255, 255, 255, 0.3);
                    }
                  }
                }
              }
            }
          }
        }
      }

      & .carousel-control-prev,
      & .carousel-control-next {
        top: 50%;
        width: auto;
        height: auto;
        font-size: 70px;
        color: black;
        @include transform(translateY(-50%));
        bottom: auto;
        left: 0px;
        opacity: 0;
        z-index: 99;
        @include transition(0.3s);

        @media #{$lg} {
          left: 30px;
          font-size: 50px;
        }

        @media #{$md} {
          left: 30px;
          font-size: 50px;
        }

        @media #{$xs} {
          left: 20px;
          font-size: 20px;
        }

        @media #{$sm} {
          font-size: 34px;
        }
      }

      & .carousel-control-next {
        right: 0px;
        left: auto;

        @media #{$lg} {
          right: 40px;
        }

        @media #{$md} {
          right: 40px;
        }

        @media #{$xs} {
          right: 20px;
        }
      }

      & .carousel-indicators {
        margin-bottom: 20px;

        & li {
          width: 8px;
          height: 8px;
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 50px;
          border: 0;

          &.active {
            width: 16px;
            background-color: $white;
            border-radius: 5px;
          }
        }
      }

      &:hover {
        & .carousel-control-prev {
          left: 70px;
          opacity: 1;
        }

        & .carousel-control-next {
          right: 70px;
          opacity: 1;
        }
      }
    }
  }
}

.slider-3 {
  padding-top: 0px;

  & .bd-example {
    & .carousel {
      & .carousel-inner {
        & .carousel-item {
          height: 850px;

          @media #{$md} {
            height: 650px;
          }

          @media #{$xs} {
            height: 550px;
          }

          @media #{$sm} {
            height: 650px;
          }

          & .carousel-caption {
            & .carousel-caption {
            }
          }
        }
      }
    }
  }
}

/*===== HEADER TOW =====*/

.header-hero {
  height: 850px;
  position: relative;

  @media #{$md} {
    height: 700px;
  }

  @media #{$xs} {
    height: 650px;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(
      rgba(232, 237, 255, 0.9),
      rgba(239, 254, 255, 0.9) 50%,
      rgba(254, 255, 244, 0.3) 68%,
      rgba(255, 255, 255, 0.12)
    );
    background: -o-linear-gradient(
      rgba(232, 237, 255, 0.9),
      rgba(239, 254, 255, 0.9) 50%,
      rgba(254, 255, 244, 0.3) 68%,
      rgba(255, 255, 255, 0.12)
    );
    background: linear-gradient(
      rgba(232, 237, 255, 0.9),
      rgba(239, 254, 255, 0.9) 50%,
      rgba(254, 255, 244, 0.3) 68%,
      rgba(255, 255, 255, 0.12)
    );
  }

  & .header-content {
    padding-top: 195px;
    position: relative;
    z-index: 9;

    @media #{$md} {
      padding-top: 130px;
    }

    @media #{$xs} {
      padding-top: 120px;
    }

    & .header-title {
      font-size: 44px;
      line-height: 55px;
      color: $text-color-one;
      font-weight: 600;

      @media #{$xs} {
        font-size: 30px;
        line-height: 40px;
      }
    }

    & .text {
      font-size: 20px;
      line-height: 32px;
      color: $text-color-three;
      margin-top: 16px;

      @media #{$xs} {
        font-size: 16px;
        line-height: 24px;
      }
    }

    & .header-btn {
      margin-top: 22px;

      & li {
        display: inline-block;
        margin: 10px 10px 0;

        & a {
          &.main-btn {
            border-radius: 50px;
            overflow: hidden;
            background-color: $white;
            @include box-shadow(0 3px 6px 0 rgba(0, 0, 0, 0.16));
            border: 0;
            line-height: 50px;

            & i {
              margin-left: 18px;
            }

            &.btn-two {
              &:hover {
                @include box-shadow(0 3px 25px 0 rgba(0, 0, 0, 0.16));
                color: $primary;
              }
            }
          }
        }
      }
    }
  }

  & .header-shape {
    position: absolute;
    bottom: 0;
    left: -6px;
    width: 102%;

    & img {
      width: 100%;
    }
  }
}
