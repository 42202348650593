* {
    font-family: "Poppins", sans-serif;
  }
  .lexicaltemplate {
    background-color: white;
    max-width: 1100px !important;
    display: flex;
    border: 1px solid rgb(245, 235, 235);
    border-radius: 7px;
    // margin-bottom: 30px;
  }
  
  .save-template {
    display: flex;
    margin-left: 700px;
    margin-bottom: 20px;
  }
  
  .templatecard {
    width: 100%;
    margin-bottom: 0px !important;
    padding-bottom: 16px !important;
  }
  .ant-tabs-nav {
    margin-bottom: 0px !important;
    width: fit-content !important;
    font-size: 13px;
  }
  
  .ant-tabs-nav-operations {
    display: none !important;
  }
  
  .font-poppins {
    font-family: poppins;
  }
  .card-height {
  }
  
  .template-done-button {
    display: inline-flex;
    padding: 13px 20px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 5px;
    margin-left: -15px;
    background-color: #214075;
  }
  
  .template-previous-button {
    display: inline-flex;
    padding: 13px 20px;
    justify-content: center;
    align-items: center;
    gap: -20px;
    border-radius: 5px;
    border: 1px solid rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    margin-left: 90px;
    margin-right: -180px;
  }
  
  .template-previous-button:hover {
    border: 1px solid blue;
    color: white;
    cursor: pointer;
  }
  
  .template-next-button {
    display: inline-flex;
    padding: 13px 20px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 5px;
    background-color: #214075;
    margin-left: 200px;
    margin-bottom: 10px;
  }
  
  .template-publish-button {
    display: inline-flex;
    padding: 13px 20px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 5px;
    background-color: #214075;
    margin-left: 150px;
    margin-bottom: 10px;
  }
  
  .template-save-button {
    display: inline-flex;
    padding: 13px 20px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 5px;
    background-color: #214075;
    margin-left: 200px;
    margin-bottom: 10px;
  }
  
  .ant-steps {
    color: black;
    font-weight: 600;
  }
  
  .ant-steps-item-title {
    font-weight: 400;
  }
  
  .steps-column {
    max-width: 900px;
    margin-left: -20px;
  }
  
  .steps {
    max-width: 1000px !important;
    margin: none;
  
    .ant-steps-item-title {
      font-family: "Poppins" !important;
    }
  
    .ant-steps-item-icon {
      background-color: #32599d !important;
      border: none !important;
    }
  
    .ant-steps-item-wait {
      .ant-steps-item-icon {
        background-color: #f0f0f2 !important;
        border: none !important;
      }
    }
  }
  
  /* ChatbotPopup.css */
  .chat-popup {
    // display: none;
    // position: fixed;
    // top: 50px;
    // bottom: 50px;
    // right: 20px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    width: 30vw;
    max-height: 380px;
    overflow-y: scroll;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    z-index: 10;
  }
  .chat-script {
    display: flex;
    top: 40px;
    // justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    width: 400px;
    // height: 400px; /* Set height if needed */
    border: 1px solid #ccc;
    margin-left: auto; /* Center the box horizontally */
    margin-right: auto; /* Center the box horizontally */
  }
  .chat-popup-open {
    // display: block;
    // position: fixed;
    // top: 40px;
    // bottom: 50px;
    // right: 0px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    width: 400px;
    min-height: 80vh;
    height: 470px;
    overflow-y: scroll;
    padding: 0px;
    transition: all 0.3s ease-in-out;
    z-index: 10;
    overflow: hidden;
  }
  
  .chat-content {
    text-align: left;
    .template-content {
      height: 525px !important;
      overflow-y: scroll;
    }
  }
  
  .open-chat-btn {
    position: fixed;
    bottom: 50px;
    right: 10px;
    display: block;
    z-index: 10;
    margin-bottom: -7%;
  }
  .modal-content {
    min-width: 600px !important;
  }
  .modal-when-col {
    // width: 100px !important;
  }
  .when-div {
    // margin-left: 20px !important;
  }
  .ant-picker {
    width: 140px !important;
  }
  .ant-select-1 {
    margin-left: 1px;
    min-height: 33.5 !important;
    width: 105px !important;
    // padding-left: 20px;
    // margin-right: 10px !important;
  }
  .when-text {
    position: relative;
    display: inline-flex;
    padding-bottom: 5px !important;
    align-items: center;
    max-width: 100%;
    height: 32px;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
  }
  .when-row {
    width: 410px !important;
  }
  ::-webkit-scrollbar {
    width: 7px; /* Adjust this value to change scrollbar width */
  }
  
  /* Customize scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(175, 175, 175); /* Color of the thumb */
    border-radius: 10px; /* Rounded corners of the thumb */
  }
  
  /* HTML: <div class="loader"></div> */
  .loader {
    width: 60px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side, #000 90%, #0000) 0 /
      calc(100% / 3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 1s steps(4) infinite;
  }
  .ant-progress-inner {
    width: 200px !important;
    height: 200px !important;
    font-size: 24px !important;
  }
  @keyframes l1 {
    to {
      clip-path: inset(0 -34% 0 0);
    }
  }
  .disabled {
    background-color: #d3d3d3; /* Set the background color to a grey shade */
    cursor: not-allowed; /* Change the cursor to indicate that the button is not clickable */
  }
  
  .active {
    font-weight: 400 !important;
    font-size: 13px !important;
  }
  