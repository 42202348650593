//
// root.scss
//

//theme-light
:root {
  min-width: fit-content !important;
  // Topbar - Default Light
  --#{$prefix}header-bg: #{$header-bg};
  --#{$prefix}header-item-color: #{$header-item-color};

  // Topbar - Dark
  --#{$prefix}header-dark-bg: #{$header-dark-bg};
  --#{$prefix}header-dark-item-color: #{$header-dark-item-color};

  --#{$prefix}topbar-search-bg: #{$topbar-search-bg};

  // Footer
  --#{$prefix}footer-bg: #{$footer-bg};
  --#{$prefix}footer-color: #{$footer-color};

  // Horizontal nav
  --#{$prefix}topnav-bg: #{$topnav-bg};
  --#{$prefix}menu-item-color: #{$menu-item-color};
  --#{$prefix}menu-item-active-color: #{$menu-item-active-color};

  // Boxed layout width
  --#{$prefix}boxed-body-bg: #{$boxed-body-bg};

  --#{$prefix}display-none: #{$display-none};
  --#{$prefix}display-block: #{$display-block};
}

//theme dark
@if $enable-dark-mode {
  @include color-mode(dark, true) {
    // Topbar - Default Light
    --#{$prefix}header-bg: #{$header-bg-dark};
    --#{$prefix}header-item-color: #{$header-item-color-dark};

    // Topbar - Dark
    --#{$prefix}header-dark-bg: #{$header-dark-bg-dark};
    --#{$prefix}header-dark-item-color: #{$header-dark-item-color-dark};
    --#{$prefix}topbar-search-bg: #{$topbar-search-bg-dark};

    // Footer
    --#{$prefix}footer-bg: #{$footer-bg-dark};
    --#{$prefix}footer-color: #{$footer-color-dark};

    // Horizontal nav
    --#{$prefix}topnav-bg: #{$topnav-bg-dark};
    --#{$prefix}menu-item-color: #{$menu-item-color-dark};
    --#{$prefix}menu-item-active-color: #{$menu-item-active-color-dark};

    // Boxed layout width
    --#{$prefix}boxed-body-bg: #{$boxed-body-bg-dark};

    // component variable
    --#{$prefix}light: #{$body-tertiary-bg-dark};
    --#{$prefix}light-rgb: #{to-rgb($body-tertiary-bg-dark)};
    --#{$prefix}dark: #{$body-tertiary-color-dark};
    --#{$prefix}dark-rgb: #{to-rgb($body-tertiary-color-dark)};

    --#{$prefix}display-none: #{$display-none-dark};
    --#{$prefix}display-block: #{$display-block-dark};

    .btn-light {
      --#{$prefix}btn-color: #{$body-secondary-color-dark};
      --#{$prefix}btn-bg: #{$body-tertiary-bg-dark};
      --#{$prefix}btn-border-color: #{$body-tertiary-bg-dark};
    }

    .btn-outline-light {
      --#{$prefix}btn-color: #{$body-secondary-color-dark};
      --#{$prefix}btn-border-color: #{$body-tertiary-bg-dark};
    }

    .btn-light,
    .btn-outline-light {
      --#{$prefix}btn-hover-color: #{$body-secondary-color-dark};
      --#{$prefix}btn-hover-bg: #{$body-tertiary-bg-dark};
      --#{$prefix}btn-hover-border-color: #{$body-tertiary-bg-dark};
      --#{$prefix}btn-active-color: #{$body-secondary-color-dark};
      --#{$prefix}btn-active-bg: #{$body-tertiary-bg-dark};
      --#{$prefix}btn-active-border-color: #{$body-tertiary-bg-dark};
      --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($body-tertiary-bg-dark)};
    }

    .btn-dark {
      --#{$prefix}btn-bg: #{$body-color-dark};
      --#{$prefix}btn-border-color: #{$body-color-dark};
      --#{$prefix}btn-hover-bg: #{$body-color-dark};
    }

    .btn-outline-dark {
      --#{$prefix}btn-color: #{$body-color-dark};
      --#{$prefix}btn-border-color: #{$body-color-dark};
    }

    .dropdown-menu {
      --#{$prefix}dropdown-border-width: 1px;
    }

    .table-light {
      --bs-table-color: #a6b0cf;
      --bs-table-bg: #32394e;
      --bs-table-border-color: #32394e;
    }

    .table-dark {
      --bs-table-color: #a6b0cf;
      --bs-table-bg: #32394e;
      --bs-table-border-color: #3a425a;
    }
  }
}
