.study-container {
  max-width: 181px;
  background-color: #eff2ff;
  margin-left: 25px !important;
  margin-top: 25px !important;
  position: relative;

}
.study-container1 {
  max-width: 181px;
  background-color: #eff2ff;
  margin-left: 25px !important;
  margin-top: 25px !important;
  position: relative;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    box-shadow: 1px 4px 13px 2px rgba(101, 101, 101, 0.22);
    transform: translateY(-5px);
  }
}

.studies-card {
  padding: 0 !important;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.25rem;
  max-width: 181px;
  min-height: 213px;

  .favorite-icon {
    background-color: #eff2ff;
    padding: 8px;

    .img-content {
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      background-color: #eff2ff;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }
    .option-button {
      margin-right: 10px !important;
      margin-left: 120px !important;
    }
    .option-button:hover {
      cursor: pointer;
      background-color: red;
    }

    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #d7ffe2 !important;
      text-wrap: wrap !important;
      text-align: start;
      line-height: 1rem;
    }
  }
  .delete-button {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    &:hover {
      background-color: #ece8e8;
      border-radius: 50%;
    }
  }
  .studies-card-content {
    padding: 20px !important;
    margin-top: 2px !important;
    min-height: 140px;
  }

  .study-title {
    font-size: 12px;
    font-weight: 600;
    color: #383838;
  }
  .study-secondary-text {
    color: #9f9f9f;
    font-size: 8px;
  }
  .delete-button {
    z-index: 2;
  }
}

.study-container:hover {
  cursor: pointer;
  background-color: #939393;
  box-shadow: 1px 4px 13px 2px rgba(76, 74, 74, 0.22);
}
.study-container1:hover {
  cursor: pointer;
 
  box-shadow: 1px 4px 13px 2px rgba(76, 74, 74, 0.22);
}

.study-container:hover .hover-text {
  opacity: 1;
  z-index: 4;
}

.hover-text-dashboard {
  position: absolute;
  bottom: 8%;
  left: 48%;
  font-size: 15px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color:darkslategrey;
 
}
.hover-text {
  position: absolute;
  bottom: 8%;
  left: 48%;
  font-size: 15px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: darkslategrey;
}


.study-container:hover .hover-text {
  opacity: 1;
  z-index: 4;
}

.studies-card {
  .favorite-icon-other {
    background-color: #e7f3ff !important;
    padding: 8px;
    .img-content-other {
      background-color: #e7f3ff !important;
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }
    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #80c1ff !important;
      text-wrap: wrap;
      text-align: start;
    }
  }
  .favorite-icon-orange {
    background-color: #ffede2 !important;
    padding: 8px;
    .img-content-orange {
      background-color: #ffede2 !important;
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }
    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #ffc49c !important;
      text-wrap: wrap;
      text-align: start;
    }
  }

  .favorite-icon-green {
    background-color: #d7ffe2 !important;
    padding: 8px;
    .img-content-green {
      background-color: #d7ffe2 !important;
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }
    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #71da8d !important;
      text-wrap: wrap;
      text-align: start;
    }
  }

  .favorite-icon-purple {
    background-color: #f2e4ff !important;
    padding: 8px;
    .img-content-purple {
      background-color: #f2e4ff !important;
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }

    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #cca5ef !important;
      text-wrap: wrap;
      text-align: start;
    }
  }

  .favorite-icon-yellow {
    background-color: #ffefcc !important;
    padding: 8px;
    .img-content-yellow {
      background-color: #ffefcc !important;
      display: flex;
      width: 100%;
      min-height: 70px;
      border: 0.25rem;
      justify-content: center;
      border-radius: 3px;
      gap: 20px;
    }

    .badge-text {
      border-radius: 50%;
      font-size: 8px;
      color: #25447a;
      background-color: #ffe084 !important;
      text-wrap: wrap;
      text-align: start;
    }
  }
}

.dropdown-item.delete {
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgb(240, 237, 237);
    color: black;

    .delete-icon {
      color: red;
    }

    .duplicate-icon {
      color: blue;
    }
  }
}


