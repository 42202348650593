



/*===========================
       25.CLIENT css 
===========================*/

/*===== Client Logo Two =====*/

.client-logo-area{
    padding-top: 80px;
    padding-bottom: 80px;
    
    & .single-client{
        & img{
            
        }
    }
}

.client-logo-2{
    background-color: $light-color-two;
}















