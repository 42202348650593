/*===========================
        28.ERROR css 
===========================*/

.testimonial-area {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $light-color-two;
}

.testimonial-2 {
  background-color: $white;
}

.single-testimonial {
  background-color: $white;
  padding: 30px;
  min-height: 450px;
  @include box-shadow(0 0px 20px 0 rgba(0, 0, 0, 0.1));

  & .testimonial-image {
    & img {
      width: 90px;
      border-radius: 50%;
      display: inline-block;
    }
    & .sub-title {
      // font-size: 14px;
      line-height: 24px;
      color: $title-color;
    }
  }
  & .testimonial-content {
    padding-top: 25px;

    & .text {
      font-size: 16px;
      line-height: 24px;
      color: $text-color-one;
      min-height: 330px;
      border-bottom: 1px solid $light-color-three;
      padding-bottom: 24px;
    }
    & .author-name {
      font-size: 18px;
      font-weight: 600;
      color: $primary;
      margin-top: 24px;
    }
    & .sub-title {
      font-size: 14px;
      line-height: 24px;
      color: $primary;
    }
  }
}

.testimonial-active-2,
.testimonial-active {
  & .slick-dots {
    text-align: center;

    & li {
      display: inline-block;
      margin: 0 3px;

      & button {
        font-size: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $light-color-three;
        border: 0;
        cursor: pointer;
      }

      &.slick-active {
        & button {
          background-color: $primary;
        }
      }
    }
  }
}

.single-testimonial-4 {
  border: 2px solid $light-color-three;
  padding: 25px 30px;
  border-radius: 8px;
  background-color: $white;

  & .testimonial-author {
    & .author-image {
      & img {
        width: 80px;
        border-radius: 50%;
      }
    }
    & .author-name {
      padding-left: 20px;

      @media #{$xs} {
        padding-left: 0;
        padding-top: 15px;
      }
      @media #{$sm} {
        padding-left: 20px;
        padding-top: 0;
      }

      & .name {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        color: $text-color-one;
      }
      & .sub-title {
        font-size: 14px;
        line-height: 24px;
        color: $primary;
      }
    }
  }
  & .testimonial-text {
    margin-top: 25px;

    & .text {
      font-size: 14px;
      line-height: 24px;
      color: $text-color-one;
    }
  }
}

.testimonial-active-4 {
  & .slick-dots {
    text-align: center;
    margin-top: 25px;

    & li {
      display: inline-block;
      margin: 0 3px;

      & button {
        font-size: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $light-color-three;
        border: 0;
        cursor: pointer;
      }

      &.slick-active {
        & button {
          background-color: $primary;
        }
      }
    }
  }
}
