// body {
//   display: flex !important;
//   font-family: "Poppins";
// }

#sidebar {
  flex: 0 0 200px;
  background-color: #1f71c7;
  padding: 20px;
  height: 100vh !important;
  width: 250px;
  display: flow-root !important;
  position: fixed;
  top: 4 !important;
  z-index: 10 !important;
}

#content {
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  body {
    flex-direction: column;
  }

  .sidebar {
    flex: 0 0 auto;
    width: 100;
  }
}

.list-item {
  display: contents !important;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}

.list-item img {
  display: flex;
}

.image {
  width: 85%;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  border: 1px dotted gray;
}

.back-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.back-to-top-btn:hover {
  background-color: #0252a7;
}
