* {
  font-family: "Poppins", sans-serif;
}
.lexicaltemplate {
  font-family: "poppins";
  .lexical-top-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 35px;
    margin-left: 2px;
    // margin-bottom: 10px;
    .top-bar-column {
      // display: flex;
      // column-gap: px;
      // justify-content: flex-end;
      height: 11px;
    }
  }
  .template-content {
    height: 50vh;
    overflow-y: scroll;
    padding-left: 25px;
    padding-top: 20px;
    
    // font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; // update after UI review
  }
  .editor-paragraph {
    // color: #383838;
    // font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    // font-size: 15px;
    // font-style: normal;
    // font-weight: 300;
    // line-height: 20px;
    // width: 800px;
    // padding: 0px !important;
    // margin-bottom: 8px !important;
  }
  .editor-heading-h3 {
    font-size: 18px;
    font-weight: 500;
    color: #151515;
  }
  .editor-heading-h1 {
    font-size: 24px;
    font-weight: 500;
    color: #151515;
  }

  .topbar-icon {
    font-size: 20px !important;
    padding: 5px;
  }
  .topbar-icon:hover {
    cursor: pointer;
    border: 10px;
    border-radius: 5px;
    background-color: #eee;
  }
  
  .editor-nested-listitem{
    list-style: none !important;
  }
  

  .editor-text-bold {
    font-weight: bold;
  }
  .editor-text-underline{
    text-decoration: underline;
  }
 .editor-text-italic{
  font-style: italic;
 }

  .editor-quote{
    margin: 0;
    margin-left: 20px;
    margin-bottom: 10px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
    font-weight: 600;
    color: #2d2d2d;
    font-size: 13px;
  }

  .editor-text-underline{
    text-decoration: underline;
  }
  .editor-heading-h2 {
    color:#151515;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .editor-list-ul {
    margin-top: 5px !important;
  }
  .divider {
    background-color: darkgray;
  }
}
