@mixin transition($time) {
	-webkit-transition: all $time ease-out 0s;
	-moz-transition: all $time ease-out 0s;
	-ms-transition: all $time ease-out 0s;
	-o-transition: all $time ease-out 0s;
	transition: all $time ease-out 0s;
}


@mixin transform($value) {
  -webkit-transform: $value;
     -moz-transform: $value;
      -ms-transform: $value;
       -o-transform: $value;
          transform: $value;
}

@mixin user-select($value) {
  -webkit-user-select: $value;
     -moz-user-select: $value;
      -ms-user-select: $value;
          user-select: $value;
}


@mixin box-sizing($value) {
  -webkit-box-sizing: $value;
     -moz-box-sizing: $value;
          box-sizing: $value;
}


@mixin animation($value) {
  -webkit-animation: $value;
     -moz-animation: $value;
       -o-animation: $value;
          animation: $value;
}


@mixin animation-delay($value) {
  -webkit-animation-delay: $value;
     -moz-animation-delay: $value;
       -o-animation-delay: $value;
          animation-delay: $value;
}



@mixin box-shadow($value) {
  -webkit-box-shadow: $value;
     -moz-box-shadow: $value;
          box-shadow: $value;
}

@mixin filter($value) {
  -webkit-filter: $value;
     -moz-filter: $value;
          filter: $value;
}


// Placeholder Mixins
@mixin placeholder {
    &::placeholder { @content; }
    &::-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}










