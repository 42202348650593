.transcript-container {
  flex: 1;
  .video-tag {
    width: 500px !important;
  }
}
.transcript-card {
  overflow-y: scroll !important;
  height: 500px !important;
}
.transcript-input {
  width: 500px;
  margin-left: 8px;
  padding: 0px !important;
  border: none !important;
  outline-offset: none !important;
}
.ant-input:hover {
  // border: none !important;
  border: 1px solid #4096ff !important;
  // border-radius: 0px;
}
.input[type="text"]:focus {
  outline: 1px solid !important;
  outline-offset: none !important;
}
.file-upload-card {
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #ccc;
  text-align: center;
}

.dropzone {
  border: 2px dashed #aaa;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.selected-files {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.file-item {
  margin-right: 10px;
  margin-bottom: 10px;
}
.generate-button{
  display: flex;
  justify-content: center; 
  align-items: center;     
  height: 10vh; 
}
.generate-description{
  display: flex;
  justify-content: center; 
  align-items: center;     
  height: 40vh; 
}
.refresh-warning {
  display: flex;
  justify-content: center !important;
  // text-align: center;
  margin-left: 32%;
  margin-top: 10px;
  color: black;
  border: 1px solid black ;
  border-radius: 10px;
  width: 250px;
  margin-bottom: 20px;
  background-color: rgb(239, 167, 167);
}
.generate-card{
  height: 78vh !important;
}
.file-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.upload-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-progress {
  margin-top: 20px;
}
