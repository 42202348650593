/*===========================
      20.CONTACT css 
===========================*/

.contact-area {
  padding-top: 95px;
  padding-bottom: 100px;
  background-color: $light-color-two;
}

.form-message {
  &.error,
  &.success {
    font-size: 16px;
    color: $text-color-one;
    padding: 10px 15px;
    margin-top: 15px;
    background-color: $light-color-one;
  }
  &.error {
    color: #fc3832;
  }
}

.single-contact-box {
  background-color: $white;
  border-radius: 8px;
  padding: 24px 35px;
  height: 255px;

  @media #{$xs} {
    padding: 24px 20px;
  }

  & .box-icon {
    position: relative;
    display: inline-block;

    & i {
      font-size: 88px;
      position: relative;
      z-index: 5;
      color: $primary;
    }
    & .shape {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  & .box-content {
    margin-top: 24px;

    & .box-title {
      font-size: 28px;
      line-height: 35px;
      color: $text-color-one;
    }
    & .text {
      font-size: 14px;
      line-height: 20px;
      color: $text-color-one;
      margin-top: 16px;
    }
  }
}

.contact-one {
  .text {
    font-size: 20px;
  }
}

.contact-two {
  & .contact-title {
    font-size: 24px;
    font-weight: 600;
    color: $title-color;
  }
  & .text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    color: $text-color-three;
  }
  & .contact-info {
    margin-top: 40px;

    & li {
      color: $text-color-three;
      margin-top: 8px;
      font-size: 16px;

      & i {
        margin-right: 5px;
        color: $text-color-one;
      }
    }
  }
}

.contact-map {
  & .gmap_canvas {
    & iframe {
      width: 100%;
      height: 400px;
    }
  }
}

.form-group {
  margin-bottom: 0;
}

.form-input {
  & label {
    font-size: 12px;
    line-height: 18px;
    color: $text-color-three;
    margin-bottom: 8px;
    display: inline-block;
  }

  & .input-items {
    position: relative;

    & input,
    & textarea {
      width: 100%;
      height: 44px;
      border: 2px solid $text-color-two;
      padding-left: 44px;
      padding-right: 12px;
      position: relative;
      font-size: 16px;
      color: $text-color-three;

      &:focus {
        border-color: $primary;
      }

      @include placeholder {
        color: $text-color-three;
        opacity: 1;
      }
    }

    & textarea {
      padding-top: 8px;
      height: 130px;
      resize: none;
    }

    & i {
      position: absolute;
      top: 11px;
      left: 13px;
      font-size: 20px;
      z-index: 9;
      color: $text-color-three;
    }
  }
}
