//
// Chat.scss
//
*{
  font-family:"Poppins";
}
.chat-leftsidebar {
  @media (min-width: 992px) {
    min-width: 260px;
  }

  @media (min-width: 1200px) {
    min-width: 380px;
  }

  .chat-leftsidebar-nav {
    .nav {
      background-color: var(--#{$prefix}secondary-bg);
    }

    .tab-content {
      min-height: 488px;
    }
  }
}

.chat-noti-dropdown {
  &.active {
    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $danger;
      border-radius: 50%;
      right: 0;
    }
  }

  .btn {
    padding: 6px;
    box-shadow: none;
    font-size: 20px;
  }
}

.chat-search-box {
  .form-control {
    border: 0;
  }
}

.chat-list {
  margin: 0;
  li {
    &.active {
      a {
        background-color: var(--#{$prefix}secondary-bg);
        border-color: transparent;
        box-shadow: $box-shadow;
      }
    }
    a {
      display: block;
      padding: 14px 16px;
      color: var(--#{$prefix}secondary-color);
      transition: all 0.4s;
      border-top: 1px solid var(--#{$prefix}border-color);
      border-radius: 4px;
      &:hover {
        background-color: var(--#{$prefix}secondary-bg);
        border-color: transparent;
        box-shadow: $box-shadow;
      }
    }
  }
}

.user-chat-nav {
  .dropdown {
    .nav-btn {
      color: var(--#{$prefix}body-color);
      height: 40px;
      width: 40px;
      line-height: 42px;
      box-shadow: none;
      padding: 0;
      font-size: 16px;
      background-color: var(--#{$prefix}light);
      border-radius: 50%;
      border: none;
    }

    .dropdown-menu {
      box-shadow: $box-shadow;
      border: 1px solid var(--#{$prefix}border-color);
    }
  }
}

.chat-conversation {
  color: black;
  .chat-heading{
    li {
      clear: both;
    }
  
    .chat-day-title {
      position: relative;
      text-align: center;
      margin-bottom: 24px;
  
      .title {
        background-color: var(--#{$prefix}secondary-bg);
        position: relative;
        z-index: 1;
        padding: 6px 24px;
      }
  
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        background-color: var(--#{$prefix}border-color);
        top: 10px;
      }
      .badge {
        font-size: 12px;
      }
    }
    .conversation-list {
     
      display: inline-block;
      position: relative;
      font-weight: 500;
      font-size: 16px;
  
      .ctext-wrap {
        padding: 6px 12px;
        // background-color: rgba(255, 165, 0, 0.4);
        border-radius: 8px 8px 8px 0px;
        overflow: hidden;
  
        .conversation-name {
          font-weight: $font-weight-semibold;
          color: $primary;
          // margin-bottom: 4px;
        }
      }
  
      .dropdown {
        float: right;
        .dropdown-toggle {
          font-size: 18px;
          padding: 4px;
          color: var(--#{$prefix}secondary-color);
          @media (max-width: 575.98px) {
            display: none;
          }
        }
  
        .dropdown-menu {
          box-shadow: $box-shadow;
          border: 1px solid var(--#{$prefix}border-color) !important;
        }
      }
  
      .chat-time {
        font-size: 12px;
      }
    }
  
    .right {
      .conversation-list {
        float: right;
        .ctext-wrap {
          background-color: var(--#{$prefix}light);
          text-align: right;
          border-radius: 8px 8px 0px 8px;
        }
        .dropdown {
          float: left;
        }
  
        &.last-chat {
          .conversation-list {
            &:before {
              right: 0;
              left: auto;
            }
          }
        }
      }
    }
  
    .last-chat {
      .conversation-list {
        &:before {
          content: "\F0009";
          font-family: "Material Design Icons";
          position: absolute;
          color: $primary;
          right: 0;
          bottom: 0;
          font-size: 16px;
  
          @media (max-width: 575.98px) {
            display: none;
          }
        }
      }
    }

  }
  .chat-paragraph{
    li {
      clear: both;
    }
  
    .chat-day-title {
      position: relative;
      text-align: center;
      margin-bottom: 24px;
  
      .title {
        background-color: var(--#{$prefix}secondary-bg);
        position: relative;
        z-index: 1;
        padding: 6px 24px;
      }
  
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        background-color: var(--#{$prefix}border-color);
        top: 10px;
      }
      .badge {
        font-size: 12px;
      }
    }
    .conversation-list {
      margin-bottom: 24px;
      display: inline-block;
      position: relative;
  //paragraph color
      .ctext-wrap {
        margin-top: 7px;
        font-size: 12px !important;
        margin-bottom: -8px !important;
        color: rgb(162, 154, 154)!important;
        font-size: 11px !important;
        margin-bottom: -5px !important ;
        overflow: hidden;
  
        .conversation-name {
          font-weight: $font-weight-semibold;
          color: $primary;
          margin-bottom: 4px;
        }
      }
      // apply this class if text include the speaker icon
      .chat-quote{
        padding: 6px 12px;
        color: black;
        background-color: rgba(48, 107, 190, 0.4) !important;
        border-radius: 8px 8px 8px 0px;
        overflow: hidden;
  
        .conversation-name {
          font-weight: $font-weight-semibold;
          color: $primary;
          margin-bottom: 4px;
        }
      }
      
  
      .dropdown {
        float: right;
        .dropdown-toggle {
          font-size: 18px;
          padding: 4px;
          color: var(--#{$prefix}secondary-color);
          @media (max-width: 575.98px) {
            display: none;
          }
        }
  
        .dropdown-menu {
          box-shadow: $box-shadow;
          border: 1px solid var(--#{$prefix}border-color);
        }
      }
  
      .chat-time {
        font-size: 12px;
      }
    }
  
    .right {
      .conversation-list {
        float: right;
        .ctext-wrap {
          background-color: var(--#{$prefix}light);
          text-align: right;
          border-radius: 8px 8px 0px 8px;
        }
        .dropdown {
          float: left;
        }
  
        &.last-chat {
          .conversation-list {
            &:before {
              right: 0;
              left: auto;
            }
          }
        }
      }
    }
  
    .last-chat {
      .conversation-list {
        &:before {
          content: "\F0009";
          font-family: "Material Design Icons";
          position: absolute;
          color: $primary;
          right: 0;
          bottom: 0;
          font-size: 16px;
  
          @media (max-width: 575.98px) {
            display: none;
          }
        }
      }
    }
  }
  .chat-list{
    li {
      clear: both;
    }
  
    .chat-day-title {
      position: relative;
      text-align: center;
      margin-bottom: 24px;
  
      .title {
        background-color: var(--#{$prefix}secondary-bg);
        position: relative;
        z-index: 1;
        padding: 6px 24px;
      }
  
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        background-color: var(--#{$prefix}border-color);
        top: 10px;
      }
      .badge {
        font-size: 12px;
      }
    }
    .conversation-list {
      margin-bottom: 24px;
      display: inline-block;
      position: relative;
  
      .ctext-wrap-list {
        padding: 6px 12px;
        background-color: rgba(255, 98, 98, 0.400) !important;
        border-radius: 8px ;
        overflow: hidden;
        margin-top: 8px !important;

        ul, li {
          color: black;
          list-style: disc;
        }
  
        .conversation-name {
          font-weight: $font-weight-semibold;
          color: $primary;
          margin-bottom: 4px;
        }
      }
  
      .dropdown {
        float: right;
        .dropdown-toggle {
          font-size: 18px;
          padding: 4px;
          color: var(--#{$prefix}secondary-color);
          @media (max-width: 575.98px) {
            display: none;
          }
        }
  
        .dropdown-menu {
          box-shadow: $box-shadow;
          border: 1px solid var(--#{$prefix}border-color);
        }
      }
  
      .chat-time {
        font-size: 12px;
      }
    }
  
    .right {
      .conversation-list {
        float: right;
        .ctext-wrap {
          background-color: var(--#{$prefix}light) !important;
          text-align: right;
          border-radius: 8px 8px 0px 8px;
        }
        .dropdown {
          float: left;
        }
  
        &.last-chat {
          .conversation-list {
            &:before {
              right: 0;
              left: auto;
            }
          }
        }
      }
    }
  
    .last-chat {
      .conversation-list {
        &:before {
          content: "\F0009";
          font-family: "Material Design Icons";
          position: absolute;
          color: $primary;
          right: 0;
          bottom: 0;
          font-size: 16px;
  
          @media (max-width: 575.98px) {
            display: none;
          }
        }
      }
    }
  }
}

.chat-input-section {
  border-top: 1px solid var(--#{$prefix}border-color);
}

.chat-input {
  border-radius: 30px;
  background-color: var(--#{$prefix}light) !important;
  border-color: var(--#{$prefix}light) !important;
  padding-right: 120px;
}

.chat-input-links {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  li {
    a {
      font-size: 16px;
      line-height: 36px;
      padding: 0px 4px;
      display: inline-block;
    }
  }
}

.chat-send {
  @media (max-width: 575.98px) {
    min-width: auto;
  }
}
.ctext-wrap-quote{
  padding: 6px 6px 6px 6px;
  background-color: rgb(238, 240, 252) !important;
  border-radius: 8px 8px 8px 0px;
  overflow: hidden;
  // margin-bottom: 7px ;
  
}
.ctext-wrap-heading{
padding: -10 !important;

  color: rgb(162, 154, 154);
  border-radius: 8px ;

  overflow: hidden;
  margin-top: 5px !important;
  
}